import React, { useState, useContext, createRef } from 'react'
import SEO from '../../components/seo'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import StoreContext from '../../context/StoreContext'
import ConnexionLayout from '../../components/account/ConnexionLayout'
import {
  Button,
  FormError,
  FormInput,
  FormLabel,
  LargerHeading,
} from '../../styles'
import styled from 'styled-components'
import Navigation from '../../components/Navigation'
import media from '../../styles/media'
import ReCAPTCHA from 'react-google-recaptcha'

const RegCon = styled.div`
  margin: var(--gutter-m);
  ${media.laptop`
    width: 40%;
    margin: var(--gutter-m) 30%;
  `}
`

const CUSTOMER_REGISTER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const CUSTOMER_LOGIN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const RegisterForm = () => {
  const { setValue } = useContext(StoreContext)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [error, setError] = useState(null)
  const [recaptchaChecked, setRecaptchaChecked] = useState(true)

  let buttonRef = createRef()

  const recaptchaChange = () => {
    setRecaptchaChecked(true)
  }

  const handleCustomerAccessToken = value => {
    setValue(value)
  }

  return (
    <>
      <Navigation />
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6 is-centered">
                <Mutation mutation={CUSTOMER_REGISTER}>
                  {customerLogin => {
                    return (
                      <RegCon>
                        <LargerHeading className="bottomSpace">
                          Create an account
                        </LargerHeading>
                        <div className="field">
                          <FormLabel className="label" htmlFor="firstName">
                            First Name
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input full"
                              type="text"
                              id="firstName"
                              onChange={e => setFirstName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <FormLabel className="label" htmlFor="lastName">
                            Last Name
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input full"
                              type="text"
                              id="lastName"
                              onChange={e => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <FormLabel className="label" htmlFor="loginEmail">
                            Email
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input full"
                              type="email"
                              id="loginEmail"
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <FormLabel className="label" htmlFor="loginPassword">
                            Password
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input full"
                              type="password"
                              id="loginPassword"
                              onChange={e => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <label htmlFor="newsletter">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        &nbsp;&nbsp; Subscribe to newsletter</label>
                        <br></br>
                        <br />
                        <ReCAPTCHA
                          sitekey="6LcDL9cZAAAAAOLTHHcBpNBRKMrsg0kYrYuIU5Lq"
                          onChange={recaptchaChange}
                        />
                        <br />
                        {error && (
                          <>
                            <FormError className="reg">{error}</FormError>{' '}
                            <br />
                            <br />
                            <br />
                          </>
                        )}
                        <Button
                          className="form-button reg"
                          disabled={!recaptchaChecked}
                          onClick={() => {
                            customerLogin({
                              variables: {
                                input: {
                                  firstName: firstName,
                                  lastName: lastName,
                                  email: email,
                                  password: password,
                                },
                              },
                            }).then(result => {
                              if (
                                result.data.customerCreate.customerUserErrors
                                  .length === 0
                              ) {
                                buttonRef.click()
                              } else {
                                setError(
                                  result.data.customerCreate
                                    .customerUserErrors[0].message
                                )
                              }
                            })
                          }}
                        >
                          Register
                        </Button>
                      </RegCon>
                    )
                  }}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Mutation mutation={CUSTOMER_LOGIN}>
        {customerLogin => {
          return (
            <>
              <Button
                className="form-button input hidden"
                ref={component => (buttonRef = component)}
                onClick={() => {
                  customerLogin({
                    variables: {
                      input: {
                        email: email,
                        password: password,
                      },
                    },
                  })
                    .then(result => {
                      if (
                        result.data.customerAccessTokenCreate.customerUserErrors
                          .length === 0
                      ) {
                        handleCustomerAccessToken(
                          result.data.customerAccessTokenCreate
                            .customerAccessToken
                        )
                      } else {
                        setError('Incorrect email or password')
                      }
                    })
                    .catch(err => {
                      alert(err)
                    })
                }}
              >
                Log in
              </Button>
            </>
          )
        }}
      </Mutation>
    </>
  )
}

const Register = () => {
  return (
    <>
      <SEO title="Register" />
      <ConnexionLayout log={false}>
        <RegisterForm />
      </ConnexionLayout>
    </>
  )
}

export default Register
